import React, { useState, useEffect } from "react";

export const MechanicalTestingPage: React.FC = () => {

    return (
        <div className="section__catalogue">
            <div className="section__catalogue__header">
                <h2 className="heading--secondary">Механические испытания</h2>
            </div>

            <div className="section__catalogue__main">

            </div>
        </div>
    );
};


import React from "react";
import { NavLink } from "react-router-dom";

export const Navigation: React.FC = () => {
    return (
        <div className="navigation">

            <input type="checkbox" className="navigation__checkbox" id="navi-toggle" />
            <label className="navigation__btn" htmlFor="navi-toggle"><span className="navigation__icon"></span></label>

            <div className="navigation__bg"></div>

            <nav className="navigation__nav">
                <ul className="navigation__list">
                    <li className="navigation__item"><NavLink className="navigation__link" to="/catalogue/optical-microscopes">Каталог</NavLink></li>
                    <li className="navigation__item"><NavLink className="navigation__link" to="/search">Поиск</NavLink></li>
                    <li className="navigation__item"><NavLink className="navigation__link" to="/about-us">О нас</NavLink></li>
                    {/* <li className="navigation__item"><NavLink className="navigation__link" to="">Контакты</NavLink></li> */}
                </ul>
            </nav>
        </div>
    );
};
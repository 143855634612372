import React from "react";
import { MainPageMain } from "./MainPageMain";
import { MainPageHeader } from "./MainPageHeader";
import { MainPageFooter } from "./MainPageFooter";
import { Navbar } from "../../components/Navbar";

export const MainPage: React.FC = ( ) => {
    return (
        <div className="page page__main">
            <Navbar />
            <MainPageHeader />
            <MainPageMain />
            <MainPageFooter />
        </div>
    );
};
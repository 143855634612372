import React from "react";
import { Navbar } from "../../components/Navbar";
import { Modal } from "../../components/Modal";
import { useUniversalContext } from "../../hooks/useUniversalContext";

export const AboutUsPage: React.FC = () => {

    const values = useUniversalContext();


    return (
        <div>
            <Navbar />
            <div className="about-us__header">
                {/* <h3 className="heading--tertiary">Проверенные производители</h3> */}
                <div className="about-us__header__preview">
                    <img src="./images/simotech-logo.svg" alt="" />
                    <p className="u-margin-top--medium paragraph--tertiary">Ваш гарант качества и успеха</p>
                </div>

                <div className="about-us__header__logo-container">
                    <div className="about-us__header__logo-container__icon--1"><img className="about-us__header__logo-container__icon" src="./images/olympus.svg" alt="" /></div>
                    <div className="about-us__header__logo-container__icon--2"><img className="about-us__header__logo-container__icon" src="./images/nikon.svg" alt="" /></div>
                    <div className="about-us__header__logo-container__icon--3"><img className="about-us__header__logo-container__icon" src="./images/zeiss.svg" alt="" /></div>
                    <div className="about-us__header__logo-container__icon--4"><img className="about-us__header__logo-container__icon" src="./images/leica.svg" alt="" /></div>
                </div>                                                                         
            </div>
            <div className="about-us__main">
                <div className="about-us__main--1">
                    <div className="about-us__main--1__description">
                        <h3 className="heading--primary">О Компании</h3>
                        <p className="paragraph--tertiary u-margin-top--large">
                            Добро пожаловать на сайт компании Симотэк – вашего надежного партнера в мире микроскопии и подготовки образцов. Мы специализируемся на поставке передового оборудования для металлографии, петрологии и геологии, сотрудничая с мировыми лидерами, такими как Leica, Zeiss, Olympus, Struers и Buehler. 
                        </p>
                        <p className="paragraph--tertiary u-margin-top--medium">
                            Наш многолетний опыт и высокая квалификация команды позволяют нам удовлетворять самые сложные запросы наших клиентов, обеспечивая их высококачественными решениями для научных и промышленных задач. 
                        </p>
                        <p className="paragraph--tertiary u-margin-top--medium">
                            Компания Симотэк имеет богатый опыт участия в тендерных закупках и аккредитации на торговых площадках России, что гарантирует нашим клиентам надежность и прозрачность всех сделок. Мы стремимся к долгосрочным партнерским отношениям, предоставляя комплексные решения и поддержку на всех этапах использования оборудования.
                        </p>
                    </div>
                    <div className="about-us__main--1__composition"> 
                            <img className="about-us__main--1__composition__img" src="./images/simotech-about-us.webp" alt="" />
                    </div>
                </div>
            </div>
            <div className="about-us__footer">
                <div className="about-us__footer__content">
                    <div className="about-us__footer__content__info">
                        <h3 className="heading--tertiary">Свяжитесь с нами</h3>
                        <p className="paragraph--tertiary u-margin-top--small">ООО "Симотэк"</p>
                    </div>
                    <ul className="about-us__footer__content__list">
                        <li className="heading--tertiary"><b>Email:</b><span className="paragraph--tertiary" style={{display: 'block', marginTop: '1rem'}}> info@simotech.com</span></li>
                        <li className="heading--tertiary u-margin-top--medium"><b>Контакты:</b>
                            <ul style={{marginTop: '1rem'}}>
                                <li className="paragraph--tertiary"><a className='link' href="tel:+79602535560">+7 960 253 55 60</a></li>
                                <li className="paragraph--tertiary u-margin-top--small "><a className='link' href="mailto:info@simotek.com">info@simotek.com</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            { values?.openModal ? <Modal /> : null }
        </div>
    );
};

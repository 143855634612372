import React, {useCallback, useState, useEffect, ChangeEventHandler} from "react";
import axios from "axios";
import { TextField, Box, Paper, Typography, List, ListItem, Theme } from "@mui/material";

import { Modal } from "../../components/Modal";
import { NavLink } from "react-router-dom";

import { useUniversalContext } from "../../hooks/useUniversalContext";
import { ip } from "../../../settings";


export const SearchPage: React.FC = () => {


    const values = useUniversalContext();

    type tResult = {
        _id: string;
        brand: string;
        model: string;
        fullName: string;
        description: string;
        type:string;
    }
    
    const [ input, setInput ] = useState<string>('');
    const [ debouncedInput, setDebouncedInput ] = useState<string>(input);
    const [ results, setResults ] = useState([]);


    const fetchItems = useCallback(async () => {
        const res = await axios.get(`${ip}/api/v1/microscopes`);
        
        setResults(res.data.data.data);

    }, []);


    let timer: any;
    
    useEffect(() => {
        timer = setTimeout(() => {
            setDebouncedInput(input)
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [input]);
    
    useEffect(() => {
        fetchItems();
    }, [debouncedInput]);
    
    console.log(debouncedInput)

    const renderedResults = results.map((result: tResult) => {
        if(result.fullName.toLowerCase().includes(debouncedInput.toLowerCase())) {
            
            return (
                <Box 
                    className="container__items__item container__items__item-no-bg"
                    key={result.model}
                >
                   <Box>
                        <NavLink className="link" to={`${window.location.origin}/catalogue/optical-microscopes/${result._id}`}>
                            <Paper
                                elevation={1}
                                sx={{
                                    padding: '3rem 1rem',
                                    margin: '1rem auto',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent:'start',
                                    alignItems: 'center'
                                }}
                            >
                                <Box 
                                    className='container__items__item__composition'
                                    sx={{
                                        display: 'flex',
                                        justifyContent:'space-around',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box
                                        className='container__items__item__img-container'
                                    >
                                        <img
                                            style={{width: '10rem'}} 
                                            src={require(`${__dirname}../../../../assets/images/equipment/microscopes/olympus/upright/bx43/microscop-olympus-bx43.png`)} alt={`${result.type} ${result.brand} ${result.model}`} 
                                        />
                                    </Box>
                                    <Box
                                       
                                        sx={{
                                            marginLeft:'5rem'
                                        }}
                                    >
                                        <p className="heading--secondary">
                                            {result.type}
                                        </p>
                                        <p className="heading--tertiary u-margin-top--medium " >
                                            {result.brand} {result.model}
                                        </p>
                                        <p className="paragraph--tertiary u-margin-top--small">
                                            {result.description.length < 250 ? result.description : `${result.description.substring(0, 250)}...`}
                                        </p>
                                    </Box>
                                </Box>
                            </Paper>
                        </NavLink>
                    </Box>
                </Box>
            );
        }
    });



return (
        <Box 
            sx={{
                padding: '5rem 10rem'
            }}
        >   
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2rem'
                }}
            >
                <Typography variant="h1">Симотэк</Typography>
                <List
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        listStyle: 'none',
                        justifyContent: 'flex-end'
                    }}
                >
                    <ListItem sx={{fontSize: '1.5rem', cursor:'pointer'}}><NavLink className='link' to='/'>Главная</NavLink></ListItem>
                    <ListItem sx={{fontSize: '1.5rem',cursor:'pointer'}}><NavLink className='link' to='/catalogue/optical-microscopes'>Оборудование</NavLink></ListItem>
                    <ListItem onClick={values?.handleOpenModal} sx={{fontSize: '1.5rem',cursor:'pointer'}}>Связаться</ListItem>
                </List>
            </Box>

            <Box>
                <TextField 
                    type="input" 
                    label="Поиск" 
                    onChange={(e) => {setInput(e.target.value)}}
                    sx={{
                        fontSize: '1.5rem',
                        marginTop: '5rem',
                        width: '100%',
                        fontFamily: 'Avenir'
                    }}
                />
            </Box>
            
            <Box 
                className="search-page__results"
                sx={{
                    padding: '5rem 0',
                }}
            >
                {renderedResults ? renderedResults : null}
            </Box>
            {values?.openModal ? <Modal /> : null}
        </Box>
    );
};
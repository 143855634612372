import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
// import { ip } from "../../settings";

export const Banner: React.FC = () => {

    // const [ top5News, setTop5News ] = useState([]);


    // const fetchTop5News = useCallback(async () => {
    //     const res = await axios.get(`${ip}/api/v1/news`);

    //     setTop5News(res.data.data.data);
    // }, []);

    // const renderedTop5News = top5News ? top5News.filter((news, index) => {
    //     return (
    //         <div>

    //         </div>
    //     );
    // }) : null;


    return (
        <div>
            <div>
                <div className="banner banner__container">
                    <img className="banner__img--1" src={require(`${__dirname}../../../assets/images/components/banner/microscope--1.webp`)} alt="микроскоп симотэк" />
                    <button className="banner-arrow banner-arrow--left"><MdArrowBackIosNew style={{fontSize: "2rem"}} /></button>
                    <button className="banner-arrow banner-arrow--right"><MdArrowForwardIos style={{fontSize: "2rem"}} /></button>
                </div>
            </div>
        </div>
    );
};
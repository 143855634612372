import React, {useState, useEffect, useCallback, useRef} from "react";
import { Input, Box, ListItem, List } from "@mui/material";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { ip } from "../../settings";

export const SearhBar: React.FC = () => {

    type tItem = {
        _id: string;
        brand: string;
        model: string;
        fullName: string
    }

    const [ searchTerm, setSearchTerm ] = useState<string>('');
    const [ debouncedTerm, setDebouncedTerm ] = useState<string>(searchTerm);
    const [ items, setItems ] = useState<[]>([]);
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const targetRef = useRef<HTMLLIElement>();


    const fetchItems = useCallback( async() => {

        const res = await axios.get(`${ip}/api/v1/microscopes`);


        setItems(res.data.data.data);

    }, []);


    let timer: any;

    useEffect(() => {
        timer = setTimeout(() => {
            setDebouncedTerm(searchTerm)
        }, 1000);   

        return () => {
            clearTimeout(timer);
        }

    }, [searchTerm]);

    useEffect(() => {
        fetchItems();

        const handleClick = (e: Event) => {

            if(!targetRef.current) return

            if(targetRef !== null && targetRef.current !== undefined) {
                if(targetRef.current.contains(e.target as HTMLElement)) {
                    setIsOpen(true);
                } else {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener('click', handleClick, true );

        return () => {
            document.removeEventListener('click', handleClick );
        }

    }, [debouncedTerm]);

    const renderedItems = items.map((item: tItem) => {

        if(item.fullName.toLowerCase().includes(debouncedTerm?.toLowerCase() as string)) {
            return (
                <ListItem 
                    className="container__items__item"
                    key={item.model}
                >
                   <p><NavLink className="link" to={`${window.location.origin}/catalogue/optical-microscopes/${item._id}`}>{item.brand} {item.model}</NavLink></p>
                </ListItem>
            );
        }  
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    padding: '2rem 5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    borderRadius: '8px',
                    backgroundColor: '#F2F2F2',
                    border: '1px solid #E0E0E0',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    color: '#444444',
                    transition: '0.3s ease-in-out',
                    '&:hover': {
                        backgroundColor: '#E0E0E0',
                    }
                }}
            >
                <Input 
                    sx={{
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                        fontSize: '1.5rem'
                    }}
                    placeholder="Введите имя продукта"
                    onChange={(e) => {
                        if(isOpen === false) setIsOpen(true);
                        setSearchTerm(e.target.value)
                    }}
                />
            </Box>
            {renderedItems ? <Box 
                    ref={targetRef}
                    sx={{
                        // position: 'absolute',
                        zIndex: '999',
                        // width: '100%',
                        backgroundColor: '#f7f7f7'
                    }}
                >
                    {debouncedTerm && isOpen ? <List>{renderedItems}</List> : null}
            </Box> : null}
        </Box>
    );
}
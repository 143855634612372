import React, {useEffect, useCallback, useState} from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useUniversalContext } from "../../hooks/useUniversalContext";
import { Navbar } from "../../components/Navbar";
import Button from '@mui/material/Button';
import { Modal } from "../../components/Modal";
import { ip } from "../../../settings";


export const ItemPage: React.FC = () => {

    type TMicroscope = {
        _id: string;
        type: string;
        slug: string;
        brand: string;
        model: string;
        description: string;
        descriptionLong: string;
        advantages: {name: string, description: string}[];
        applications: string[];
        specs: {magnification: string, resolution: string}[];
        contrastMethods: string[];
    }

    const {id} = useParams();

    const [fetchedItem, setFetchedItem] = useState<TMicroscope>();
    
    const values = useUniversalContext();


    const fetchItem = useCallback(async () => {
        const res = await axios.get(`${ip}/api/v1/microscopes/${id}`);
        setFetchedItem(res.data.data);
    }, []);
  
    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <div>
            {
                !fetchedItem ? <h3>Loading...</h3> :
                <div>
                    <Navbar />
                    <div className="item item__container">
                        <div className="item item__header">
                            <div className="item item__header--right">
                                <img 
                                    src={require(`${__dirname}../../../../assets/images/equipment/microscopes/olympus/upright/bx43/microscop-olympus-bx43.png`)} 
                                    alt={`${fetchedItem.type} ${fetchedItem.brand} ${fetchedItem.model}`}
                                />
                            </div>
                            <div className="item item__header--left">
                                <h1 className="item item__heading item__heading--primary heading--main">{fetchedItem.brand} {fetchedItem.model}</h1>
                                <p className="item item__description__preview">{fetchedItem.description}</p>
                                <div className="">
                                    <Button sx={{marginTop: '5rem', fontSize: '1.7rem'}} onClick={values?.handleOpenModal} variant="outlined">Оставить заявку</Button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="item item__main">
                            <div className="item item__main__description">
                                <h1 className="heading--main">{fetchedItem.type} {fetchedItem.brand} {fetchedItem.model}</h1>
                                <p className="item item__main__description--long">{fetchedItem.descriptionLong}</p>
                            </div>
                            {fetchedItem.advantages ? <div>
                                <h4 className="heading--tertiary u-margin-top--large">Преимущества</h4>
                                <ul className="u-margin-top--medium">
                                    {fetchedItem.advantages.map( (advantage: {name: string, description: string}) => <li className="item__li u-margin-top--small"><b>{advantage.name}</b>: {advantage.description}</li>)}
                                </ul>
                            </div> : null}
                            <div className="item item__main__specs">
                                <h4 className="heading--tertiary u-margin-top--large">Характеристики</h4>
                                <ul className="item item__main__list">
                                    {fetchedItem.specs.map(spec => <li><b>Увеличение:</b> {spec.magnification}</li>)}
                                    <li><b>Контрастирование:</b> {fetchedItem.contrastMethods.map(contrast => <span>{contrast}, </span>)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {values?.openModal ? <Modal /> : null}
        </div>
    );
};
import React from "react";
import { Button } from "@mui/material";
import { useUniversalContext } from "../hooks/useUniversalContext";
import { NavLink } from "react-router-dom";

export const Footer = () => {

    const values = useUniversalContext();

    return (
        <footer className="footer">
            <div className="footer__segment--left">
               <Button sx={{fontSize: '1.7rem'}} onClick={values?.handleOpenModal} variant="contained">Оставить заявку</Button> 

               <ul className="footer__list u-margin-top--medium">
                    <li><b>ООО Симотэк</b></li>
                    <li className="u-margin-top--small">Устанавливаем проверенное оборудование<span style={{display:'block'}}>с гарантией качества</span></li>
               </ul>
            </div>
            <div className="footer__segment--center">
                <ul className="footer__list">
                    <li><NavLink className='footer__link' to='catalogue/optical-microscopes'>Каталог</NavLink></li>
                    <li><NavLink className='footer__link' to='/search'>Поиск</NavLink> </li>
                    <li><NavLink className='footer__link' to='/about-us'>О нас</NavLink></li>
                </ul>
            </div>
            <div className="footer__segment--right">
                <ul className="footer__list">
                    <li className="heading--tertiary"><b>Контакты</b></li>
                    <li className="paragraph--tertiary u-margin-top--small">Телефон: <a className='link' href="tel:+79602535560">+7 960 253 55 60</a></li>
                    <li className="paragraph--tertiary u-margin-top--small">Email: <a className='link' href="mailto:info@simotek.com">info@simotek.com</a></li>
                </ul>
                <ul className="u-margin-top--medium">
                    <li className="paragraph--quaternary">Copyright © 2024 Все права защищены</li>
                    <li className="paragraph--quaternary">ООО Симотэк</li>
                </ul>
            </div>
            
        </footer>
    );
};
import React, {useEffect, useCallback, useState} from "react";
import axios from "axios";
import { NavLink, Outlet } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import { Modal } from "../../components/Modal";
import { useUniversalContext } from "../../hooks/useUniversalContext";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Box } from "@mui/material";
import { SearhBar } from "../../components/SearchBar";
import { ip } from "../../../settings";



export const CataloguePage: React.FC = () => {

  type tCategory = {
    _id: string;
    category: string;
    subcategories: string[];
  }

  const [ extendedIndex, setExtendedIndex] = useState<number>(-1);
  const [ categories, setCategories ] = useState<tCategory[]>([]);
 
  const values = useUniversalContext();

  const handleExtended = (nIndex: number) => {
    if(extendedIndex === nIndex) {
      setExtendedIndex(-1);
    } else {
      setExtendedIndex(nIndex);
    }
  };


  const fetchCategories = useCallback(async() => {
                                  
    const res = await axios.get(`${ip}/api/v1/categories`);


    setCategories(res.data.data.data);
  
}, []);


useEffect(() => {
  fetchCategories();


}, [])


  const renderedMicroscopeSubcategories = categories ? categories.map((curCategory: tCategory, index:number) => {

      let content;

      extendedIndex === index ? content = curCategory.subcategories.map((category:string) => {
        return (
          <li className="catalogue__item__subcategory" key={category}>{category}</li>
        )
      }) : <div />;

      return (
        <ul className="catalogue__container">
          <li className="catalogue__item">
            <div className="catalogue__item__composition">
              <span onClick={() => handleExtended(0)} className="catalogue__item__icon">{extendedIndex === 0 ? <IoIosArrowDown /> : <IoIosArrowForward /> }</span>
              <NavLink className='catalogue__item__link' to="/catalogue/optical-microscopes">
                Оптические микроскопы
              </NavLink>
            </div>
            {extendedIndex === 0 ? <ul>{content}</ul> : null}
          </li>
          {/* <li className="catalogue__item">
            <div className="catalogue__item__composition">
              <span onClick={() => handleExtended(1)} className="catalogue__item__icon">{extendedIndex === 1 ? <IoIosArrowDown /> : <IoIosArrowForward /> }</span>
              <NavLink className='catalogue__item__name' to="/catalogue/electron-microscopes">
                Электронные микроскопы
              </NavLink>
            </div>
          </li> */}
          <li className="catalogue__item">
            <div className="catalogue__item__composition">
              <span onClick={() => handleExtended(2)} className="catalogue__item__icon">{extendedIndex === 2 ? <IoIosArrowDown /> : <IoIosArrowForward /> }</span>
              <NavLink className='catalogue__item__link' to="/catalogue/sample-preparation">
                Пробоподготовка
              </NavLink>
            </div>
          </li>
          <li className="catalogue__item">
            <div className="catalogue__item__composition">
              <span onClick={() => handleExtended(3)} className="catalogue__item__icon">{extendedIndex === 3 ? <IoIosArrowDown /> : <IoIosArrowForward /> }</span>
              <NavLink className='catalogue__item__link' to="/catalogue/mechanical-testing">
                Механические испытания
              </NavLink>
            </div>
          </li>
          <li className="catalogue__item">
            <div className="catalogue__item__composition">
              <span onClick={() => handleExtended(4)} className="catalogue__item__icon">{extendedIndex === 4 ? <IoIosArrowDown /> : <IoIosArrowForward /> }</span>
              <NavLink className='catalogue__item__link' to="/catalogue/consumables">
                Расходные материалы
              </NavLink>
            </div>
          </li>
        </ul>
      )
    
  }) : null;



  return (
      <Box>
        <Navbar />
        <Box
          sx={{
            paddingTop: '10rem',
            paddingLeft: '10rem'
          }}
        >
          <h3 className="heading--secondary">Поиск</h3>
        </Box>
        <Box
          sx={{
            padding: '1rem 10rem'
          }}
        >
          <SearhBar />
        </Box>

        <div className="catalogue">
          <div className="catalogue__header">
            <div className="catalogue__categories-container">
                {renderedMicroscopeSubcategories}
            </div>
            <div className="catalogue__category">
                    <Outlet />
            </div>
          </div>
        </div>  
        { values?.openModal ? <Modal /> : null}
      </Box>
    );
};
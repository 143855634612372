import React from "react";
import { Navbar } from "../../components/Navbar";
import { Banner } from "../../components/Banner";

export const MainPageHeader: React.FC = () => {
    return (
        <div>
            <Banner />
        </div>
    );
};
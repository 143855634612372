import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useUniversalContext } from "../../../hooks/useUniversalContext";
import { ip } from "../../../../settings";

export const OpticalMicroscopePage: React.FC = () => {

    const [microscopes, setTop3Microscopes] = useState<[]>([]);

  type TMicroscope = {
        _id: string;
        slug: string;
        brand: string;
        model: string;
        description: string;
        applications: string[];
        type: string;
    }

    const fetchMicroscopes = useCallback(async () => {
        const res = await axios.get(`${ip}/api/v1/microscopes`);

        setTop3Microscopes(res.data.data.data);
    }, []);

    useEffect(() => {
        fetchMicroscopes();
    }, []);

    const renderedMicroscopes = microscopes ? microscopes.map((microscope: TMicroscope) => {
        return (
            <NavLink className='link' to={`${window.location.href}/${microscope._id}`}>
                <div key={microscope._id} className="category__item">
                    <figure className="category__item__shape">
                        <img className="category__item__img" src="https://www.spachoptics.com/v/vspfiles/photos/OLYMPUS-BX43-2.jpg?v-cache=1582096983" alt="" />
                        <figcaption className="category__item__caption">{microscope.brand} {microscope.model}</figcaption>
                    </figure>
                    <div className="category__item__content">
                        <div>
                            <h3 className="heading--quaternary">{microscope.type}</h3>
                            <h1 className="heading--tertiary u-margin-top--medium">{microscope.brand} {microscope.model}</h1>
                            <div className="category__description">
                                <p className="paragraph--tertiary">{microscope.description.length > 100 ? `${microscope.description.substring(0, 100)}...` : microscope.description}</p>
                            </div>
                        </div>
                        <div className="category__footer">
                            {/* <div className="category__footer__description">
                                <h3>Тип прибора:</h3>
                                
                            </div> */}
                            
                            <div className="category__footer__applications">
                                {/* <h4>Применение:</h4> */}
                                {/* <ul>
                                    {microscope.applications.map((application, index: number) => {
                                        if(index > 2) {
                                            return null;
                                        } 
                                        return <li key={application} className="category__item__list-item">{microscope.applications.length === 1 ? application : (microscope.applications.length === index+1 ? application : `${application}, `) }</li>
                                    })}
                                </ul> */}
                            </div>

                            
                        </div>
                    </div>
                </div>
            </NavLink>
        );
    }) : null;

    return (
        <div className="section__catalogue">
            <div className="section__catalogue__header">
                <h2 className="heading--secondary">Оптические микроскопы</h2>
            </div>

            <div className="section__catalogue__main">
                {renderedMicroscopes}
            </div>
        </div>
    );
};

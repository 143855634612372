import React, {useState, useCallback, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';
import axios from "axios";

import { Button, ListItem, Typography, SvgIcon } from "@mui/material";
import { useUniversalContext } from "../hooks/useUniversalContext";
import { ip } from "../../settings";

import {ReactComponent as SimotekIcon} from '../../assets/images/logos/simotek-logo.svg';

export const Navbar: React.FC = () => {

    type tItem = {
        _id: string;
        brand: string;
        model: string;
        fullName: string
    }

    const values = useUniversalContext();

    const [ items, setItems ] = useState([]);
    const [ input, setInput ] = useState<string>();
    const [ debounce, setDebounce] = useState(input);
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    const targetEl = useRef<HTMLElement>(null);

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        if(isOpen === false) setIsOpen(true);
        setInput(e.currentTarget.value)
      }
    

    const fetchProducts = useCallback(async () => {
        const res = await axios.get(`${ip}/api/v1/microscopes`);


        setItems(res.data.data.data);
    }, []);


    const renderedItems = items.filter((item:tItem) => {
        return item.fullName.toLowerCase().includes(debounce?.toLowerCase() as string)
    }).map((item: tItem) => {

        // if(item.fullName.toLowerCase().includes(debounce?.toLowerCase() as string)) {
            return (
                <ListItem 
                    className="container__items__item"
                    key={item.model}
                >
                   <p><NavLink className="link" to={`${window.location.origin}/catalogue/optical-microscopes/${item._id}`}>{item.brand} {item.model}</NavLink></p>
                </ListItem>
            );
        // }  
    });

    
    let timer: any;

    useEffect(() => {
        timer = setTimeout(() => {
            setDebounce(input)
        }, 1000)

        return () => {
            clearTimeout(timer)
        }



    }, [input]);

    useEffect(() => {
        if (!debounce) {
            return;
        } else if(!debounce) {
            setItems([]);
            return;
        } 

        const handleClick = (e: Event) => {
            
            console.log(targetEl)
            if(targetEl.current !==null && targetEl.current !== undefined) {
                console.log(targetEl.current.contains(e.target as HTMLElement))
                if(targetEl.current.contains(e.target as HTMLElement)) {
                    setIsOpen(true);
                } else {
                    setIsOpen(false);
                }
            }
        }

        document.addEventListener('click', handleClick, true);

        fetchProducts()

        return () => {
            setItems([]);
            document.removeEventListener('click', handleClick );
        }
    }, [debounce])



    return (
        <nav className="navbar">
            <ul className="navbar--left">
                <li className="navbar__link-left paragraph--tertiary"><NavLink className='link' to='/catalogue/optical-microscopes'>Каталог</NavLink></li>
                <li className="navbar__link-left paragraph--tertiary"><NavLink className='link' to='/about-us'>О нас</NavLink></li>
                <li className="navbar__link-left navbar__link-left-display paragraph--tertiary"><a className='link' href="tel:+79602535560">+7 960 253 55 60</a></li>
                <li className="navbar__link-left navbar__link-left-display paragraph--tertiary"><a className='link' href="mailto:info@simotek.com">info@simotek.com</a></li>
            </ul>


            <div className="navbar__icon-container">
                <NavLink to='/' className="navbar__icon-link">
                    {/* <picture>
                        <img 
                            className='navbar__icon' 
                            src={require(`${__dirname}../../../assets/images/logos/simotek-logo.svg`)}
                            alt='Симотэк лого'
                        />
                    </picture> */}
                    
                    <SvgIcon 
                        fontSize="large"
                        component={SimotekIcon} 
                        viewBox="0 0 1475.19 430.7" 
                    />
                </NavLink>
            </div>
            
            <div className="navbar__input-container">
                <input 
                    type="text" 
                    className="navbar__input"
                    placeholder="Поиск" 
                    onChange={onChange}
                />
                    { items && isOpen ? <div className={`container__items${renderedItems.length > 0 ? '' : '-hidden'}`}>
                    { items && isOpen ? <ul  
                        ref={targetEl as React.RefObject<HTMLUListElement>}
                        className={`container__items__content`}
                    >
                    <Typography
                        variant='body1'
                        sx={{
                            fontSize: '1.3rem'
                        }}
                        ><b>Найдено:</b> {renderedItems.length ? renderedItems.length : 0}
                    </Typography>
                        {renderedItems}
                    </ul> : null}
                </div> : null }
            </div>


            <ul className="navbar--right">
                <li className="navbar__link-right"><Button sx={{fontSize: '1.7rem'}} onClick={values?.handleOpenModal} variant="contained">Связаться</Button></li>
            </ul>
        </nav>
    );
};
import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import { IMicroscope } from "../../../back/models/Microscope";
import { useUniversalContext } from "../../hooks/useUniversalContext";
// ICONS
import { MdHighQuality } from "react-icons/md";
import { IoSpeedometer } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { FaBookOpen } from "react-icons/fa6";
import { GiGiftOfKnowledge } from "react-icons/gi";

// COMPONENTS
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { Navigation } from "../../components/Navigation";
import { ip } from "../../../settings";

export const MainPageMain = () => {

    const values = useUniversalContext();


    const [ top3Microscopes, setTop3Microscopes ] = useState<[]>();
    const [ featureDescriptionShow, setFeatureDescriptionShow ] = useState<boolean>(false);

    const fetchTop3Microscopes = useCallback(async () => {
        const res = await axios.get(`${ip}/api/v1/microscopes`);


        setTop3Microscopes(res.data.data.data);
    }, []);

    useEffect(() => {
        fetchTop3Microscopes();
    }, []);

    const renderedTop3Microscopes = top3Microscopes ? top3Microscopes.map((microscope:IMicroscope, index: number) => {
        
        while(index <=2) {
            return <div className={`card card__top-3--${index+1}`}>
            <div className="card card-side card-side--front">
                <div className={`card__img card__img--${index+1}`}>
                    <h3 className="heading-card"><span className="heading-span" >{microscope.brand} {microscope.model}</span></h3>
                </div>
                <div className="card card-side--front__info">
                    <h5 className="heading--tertiary">Характеристики</h5>
                    <ul className="card-side--front__info__list">
                        <li className="card card__list-item"><span>Увеличение:</span></li>
                        <li className="card card__list-item">Объективы:</li>
                    </ul>
                </div>  
            </div>

            <div className="card card-side card-side--back">
                <h3 className="card-side--back__heading heading--tertiary">Применение</h3>
                <ul className="card card__list">
                    <li className="card card__list-item">{microscope.applications[0]}</li>
                    <li className="card card__list-item">{microscope.applications[1]}</li>
                    <li className="card card__list-item">{microscope.applications[2]}</li>
                </ul>

                <div className="card card__container--contrast">
                    <h5 className="heading--tertiary">Методы контрастирования</h5>
                    <ul className="u-margin-top--small">
                        {microscope.contrastMethods.map((method) => {
                            return <li className="item item--li" key={method}>{method}</li>
                        })}
                    </ul>
                </div>

                <div className="card card__fields-container">
                    <div>
                        <button onClick={values?.handleOpenModal} className={`card card__input-field--order`}>Оставить заявку</button>
                    </div>
                    <div className={`card card__input-field--special`}>
                        {/* <p><span className="item__name">Скидка:</span> <span className="item__value">10%</span></p> */}
                    </div>
                </div>
            </div>
        </div>
        }
    }) : null;


    return (
        <main>
            <section className="section section__brands">
                <div className="section__brands__field">
                    <img className="section__brands__brand" src="./images/olympus.svg" alt="olympus" />
                </div>

                <div className="section__brands__field">
                     <img className="section__brands__brand" src="./images/zeiss.svg" alt="zeiss" />
                </div>

                <div className="section__brands__field">
                    <img className="section__brands__brand" src="./images/nikon.svg" alt="nikon" />
                </div>

                <div className="section__brands__field">
                    <img className="section__brands__brand" src="./images/leica.svg" alt="leica" />
                </div>
            </section>  
            <section className="section section__company-features">
                <div className="section__company-features__card section__company-features__card--1"
                    onMouseOver={() => setFeatureDescriptionShow(true)}
                    onMouseLeave={() => setFeatureDescriptionShow(false)}     
                >
                    <p className="section__company-features__card__heading heading--tertiary heading--tertiary-feature u-center">Качество</p>
                    <span className="icon icon__feature"><MdHighQuality/></span>
                    <p className="section__company-features__card__description">
                        Опыт профессионалов
                    </p>
                </div>
                <div className="section__company-features__card section__company-features__card--2"
                    onMouseOver={() => setFeatureDescriptionShow(true)}
                    onMouseLeave={() => setFeatureDescriptionShow(false)}     
                >
                    <p className="section__company-features__card__heading heading--tertiary heading--tertiary-feature u-center">Быстрая обработка запросов</p>
                    <span className="icon icon__feature"><IoSpeedometer/></span>
                    <p className="section__company-features__card__description">
                       1 - 3 дня до получения КП
                    </p> 
                </div>

                <div className="section__company-features__card section__company-features__card--3"
                    onMouseOver={() => setFeatureDescriptionShow(true)}
                    onMouseLeave={() => setFeatureDescriptionShow(false)}     
                >
                    <p className="section__company-features__card__heading heading--tertiary heading--tertiary-feature u-center">Своевременная доставка</p>
                    <span className="icon icon__feature"><TbTruckDelivery /></span>
                    <p className="section__company-features__card__description">
                        Доставка в кратчайшие сроки и гарантией безопасности
                    </p> 
                </div>
                <div className="section__company-features__card section__company-features__card--4"
                    onMouseOver={() => setFeatureDescriptionShow(true)}
                    onMouseLeave={() => setFeatureDescriptionShow(false)}     
                >
                    <p className="section__company-features__card__heading heading--tertiary heading--tertiary-feature u-center">Всегда улучшаемся сами</p>
                    <span className="icon icon__feature"><FaBookOpen /></span>
                    <p className="section__company-features__card__description">
                        Стажировки и повышение квалификации наших специалистов
                    </p> 
                </div>

                <div className="section__company-features__card section__company-features__card--5"
                    onMouseOver={() => setFeatureDescriptionShow(true)}
                    onMouseLeave={() => setFeatureDescriptionShow(false)} 
                >
                    <p className="section__company-features__card__heading heading--tertiary heading--tertiary-feature u-center">Опыт профессионалов</p>
                    <span className="icon icon__feature"><GiGiftOfKnowledge /></span>
                    <p className="section__company-features__card__description">
                        Все специалисты имеют научный и практический опыт
                    </p> 
                </div>
            </section>

            <section className="section__streams">
                    <div className="section__streams__container section__streams__container--1 section__streams__container--left u-margin-top--large">
                        <div className="section__streams__container--left-content">
                            <h3 className="heading--main">Металлография</h3>
                            <p className="paragraph--tertiary u-margin-top--medium">
                                Мы предлагаем микроскопы и оборудование для анализа микроструктуры металлов и сплавов. Все от подготовки шлифов до анализ зерна, включений по методикам ГОСТ. Наши решения важны для автомобильной, аэрокосмической и машиностроительной промышленности.
                            </p>
                        </div>
                        
                        <div className="section__streams__container--left-content-composition">
                            <img className="section__streams__container__img"  src={require(`${__dirname}../../../../assets/images/pages/main-page//main/composition-1.jpg`)} alt="металлография симотэк" />
                        </div>
                    </div>                                      
                    <div className="section__streams__container section__streams__container--2 section__streams__container--right u-margin-top--large"> 
                        <div className="section__streams__container--right-content">
                            <h3 className="heading--main">Петрография</h3>
                            <p className="paragraph--tertiary u-margin-top--medium">Наше оборудование позволяет подготовку керна и образцов для детального анализа минерального состава и текстуры горных пород. Это необходимо для разработки месторождений и изучения геологических процессов.</p>
                        </div> 

                        <div className="section__streams__container--right-content-composition">
                            <img className="section__streams__container__img"  src={require(`${__dirname}../../../../assets/images/pages/main-page//main/composition-2.jpg`)} alt="петрография симотэк" />
                        </div>
                    </div>                                      
                    <div className="section__streams__container section__streams__container--3 section__streams__container--left u-margin-top--large">
                        <div className="section__streams__container--left-content">
                            <h3 className="heading--main">Биология</h3>
                            <p className="paragraph--tertiary u-margin-top--medium">Мы предлагаем процессоры для обработки тканей, ультрамикротомы и электронные микроскопы. Это позволяет проводить высокоточный анализ биологических образцов для медицины и биотехнологий.</p>
                        </div> 
                        <div className="section__streams__container--left-content-composition">
                            <img className="section__streams__container__img"  src={require(`${__dirname}../../../../assets/images/pages/main-page//main/composition-3.jpg`)} alt="биология симотэк" />
                        </div>
                    </div>
                    <div className="section__streams__container section__streams__container--4 section__streams__container--right u-margin-top--large"> 
                        <div className="section__streams__container--right-content">
                            <h3 className="heading--main">Подготовка образцов для электронной микроскопии</h3>
                            <p className="paragraph--tertiary u-margin-top--medium">Симотэк предлагает резаки, ионные пучки и испарители для подготовки образцов электронной микроскопии. Наше оборудование обеспечивает высокую точность при подготовке образцов для анализа.</p>
                        </div> 

                        <div className="section__streams__container--right-content-composition">
                            <img className="section__streams__container__img" src={require(`${__dirname}../../../../assets/images/pages/main-page//main/composition-4.jpg`)} alt="пробоподготовка симотэк" />
                        </div>
                    </div>  
            </section>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h3 className="section__top-3__heading heading--secondary u-margin-top--large u-margin-left--small">Что часто ищут</h3>
            <section className="section section__top-3">
                {renderedTop3Microscopes}
            </section>
            <br/>
            <br/>
            <br/>
            <section>
                <Navigation />
            </section>
            {values?.openModal ? <Modal /> : null}
        </main>
    );
};
import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { createPortal } from "react-dom";

import { useUniversalContext } from "../hooks/useUniversalContext";

export const Modal: React.FC = () => {

    const values = useUniversalContext();

    return (
        createPortal(
            <Box className="modal modal__outside">
                <Box sx={
                    {}
                } className="modal modal__inside">
                    <div className="modal__field modal__field--heading">
                        <h4 className="heading--main">Оставьте ваши контакты</h4>
                        <p className="heading--tertiary">и мы свяжемся с Вами</p>
                    </div>
                    <div className="modal__field modal__field--name">
                        {/* <label htmlFor="name">Name</label> */}
                        <TextField 
                            required
                            className="modal__field-item"
                            type='text'
                            label="Имя" 
                            variant="outlined" 
                            inputProps={{style: {fontSize: '1.7rem'}}}
                        />
                        {/* <input type="text" name="name" /> */}
                    </div>
                    <div className="modal__field modal__field--email">
                        {/* <label htmlFor="email">Почта</label> */}
                        <TextField 
                            required
                            className="modal__field-item"
                            type='email' 
                            label="Почта" 
                            variant="outlined" 
                            inputProps={{style: {fontSize: '1.7rem'}}}
                        />
                        {/* <input type="email" name="email" /> */}
                    </div>
                    <div className="modal__field modal__field--contact">
                        {/* <label htmlFor="phone">Телефон</label> */}
                        <TextField 
                            className="modal__field-item"
                            type='tel' 
                            label="Контакты" 
                            variant="outlined" 
                            inputProps={{style: {fontSize: '1.7rem'}}}
                        />
                        {/* <input type="tel" name="phone"/> */}
                    </div>
                    <div className="modal__field modal__field--comment">
                        {/* <label htmlFor="comment">Комментарий</label> */}
                        <TextField
                            required
                            className="modal__field-item"
                            type='text'
                            label="Комментарии" 
                            // multiline
                            minRows={4}
                            maxRows={8}
                            variant="outlined" 
                            inputProps={{style: {fontSize: '1.7rem'}}}
                        />
                        {/* <input type="text" name="comment"/> */}
                    </div>
                     <div 
                        className="modal__field modal__field--btn"
                    >
                        <Button sx={{fontSize: '1.7rem'}} onClick={() => values?.handleSubmit}>Отправить</Button>
                        <Button sx={{fontSize: '1.7rem'}} onClick={values?.handleOpenModal}>Отмена</Button>
                    </div>
                </Box>
            </Box>,
            document.querySelector('.modal__container')!
        )
    );
};

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import e from "express";
import React, { createContext, useState, useEffect } from "react";

type IUniversalContext = {
        openModal: boolean;
        handleOpenModal: () => void;
        handleSubmit: (e: Event) => void;
}

interface IUniversalProvider {
    children: ReactJSXElement;
}

export const UniverstalContext = createContext<IUniversalContext | null>(null);

export const UniversalProvider = (children: IUniversalProvider) => {

    const [openModal, setOpenModal] = useState<boolean>(false);


    const handleOpenModal = () => {
            setOpenModal(!openModal);
    };

    const handleSubmit = (e: Event) => {
        e.preventDefault();

        setOpenModal(false);
    }

    useEffect(() => {
        if(openModal) document.body.style.overflow ='hidden'
        

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [openModal]);

    const values:IUniversalContext = {
        handleOpenModal,
        handleSubmit,
        openModal
    }

    return (
        <UniverstalContext.Provider value={values}>
            {children.children}
        </UniverstalContext.Provider>
    );
};

export default UniverstalContext


import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


//PAGES
import { MainPage } from './front/pages/MainPage/MainPage';
import { CataloguePage } from './front/pages/CataloguePage/CataloguePage';
import { OpticalMicroscopePage } from './front/pages/Equipment/OpticalMicroscopePage/OpticalMicroscopePage';
import { ElectronMicroscopePage } from './front/pages/Equipment/ElectronMicroscopePage/ElectronMicroscopePage';
import { SamplePreparationPage } from './front/pages/Equipment/SamplePreparationPage/SamplePreparationPage';
import { MechanicalTestingPage } from './front/pages/Equipment/MechanicalTestingPage/MechanicalTestingPage';
import { ConsumablesPage } from './front/pages/Equipment/ConsumablesPage/ConsumablesPage';
import { ItemPage } from './front/pages/ItemsPage/ItemPage';
import { SearchPage } from './front/pages/SearchPage/SearchPage';
import { AboutUsPage } from './front/pages/AboutUs/AboutUsPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
    errorElement: <h4>Page Not Found 404</h4>
  },
  {
    path: '/catalogue',
    element: <CataloguePage />,
    errorElement: <h4>Page Not Found 404</h4>,
    children: [
      {
        path:'optical-microscopes',
        element: <OpticalMicroscopePage />,
        errorElement: <h4>Page Not Found 404</h4>,
      },
      {
        path: 'electron-microscopes',
        element: <ElectronMicroscopePage/>,
        errorElement: <h4>Page Not Found 404</h4>
      },
      {
        path: 'sample-preparation',
        element: <SamplePreparationPage />,
        errorElement: <h4>Page Not Found 404</h4>
      },
      {
        path: 'mechanical-testing',
        element: <MechanicalTestingPage />,
        errorElement: <h4>Page Not Found 404</h4>
      },
      {
        path: 'consumables',
        element: <ConsumablesPage />,
        errorElement: <h4>Page Not Found 404</h4>
      }
    ],
  
  },
  {
    path: '/catalogue/optical-microscopes/:id',
    element: <ItemPage />,
    errorElement: <h4>Page Not Found 404</h4>
  },
  {
    path: '/search',
    element: <SearchPage />,
    errorElement: <h4>Page Not Found 404</h4>
  },
  {
    path: '/about-us',
    element: <AboutUsPage />,
    errorElement: <h4>Page Not Found 404</h4>
  }
])

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
